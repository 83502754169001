<template>
<div class="container-fluid py-4">
    <div class="row">
        <h2 class="text-center">Besuchertabelle</h2>
        <div class="card mb-3">
            <div class="card-header">
                <div class="row mb-3">
                    <div class="col-lg-1 col-md-4 col-6 mb-2">
                        <label>Pro Seite</label>
                        <select v-model="customPaginate" class="form-select">
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>

                    <div class="col-lg-2 col-md-4 col-6 mb-2">
                        <label>Admin / Besucher</label>
                        <select v-model="userType" class="form-select">
                            <option value="">Alle</option>
                            <option value="1">Admin</option>
                            <option value="0">Besucher</option>
                        </select>
                    </div>

                    <div class="col-lg-2 col-md-4 col-6 mb-2">
                        <label>Kontinent / Continent</label>
                        <select v-model="selectedContinent" class="form-select" @change="getVisitorCountriesByContinent(selectedContinent)">
                            <option value="0">Alle</option>
                            <option v-for="(continent, index) in continents" :key="index" :value="continent">{{ continent }}</option>
                        </select>
                    </div>

                    <div class="col-lg-1 col-6 mb-2">
                        <label>Land / Country</label>
                        <select v-model="selectedCountry" class="form-select" @change="getVisitorCitiesByCountry(selectedCountry)">
                            <option value="0">Alle</option>
                            <option v-for="(country, index) in countries" :key="index" :value="country">{{ country }}</option>
                        </select>
                    </div>

                    <div class="col-lg-2 col-md-6 col-12 mb-2">
                        <label>Stadt / City</label>
                        <select v-model="selectedCity" class="form-select">
                            <option value="0">Alle</option>
                            <option v-for="(city, index) in cities" :key="index" :value="city">
                                {{ city }}
                            </option>
                        </select>
                    </div>

                    <div class="col-lg-4 col-12 mb-2">
                        <label>Search / Suchen</label>
                        <input 
                            type="search" 
                            v-model="search" 
                            class="form-control" 
                            placeholder="Suchen Sie nach Stadt, Land oder Kontinent">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-2 col-12 text-md-start mb-2">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Mit geprüft ({{ checkedVisitors.length }})
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item" :href="exportUrl">Exportieren</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-10 col-12">
                        <strong>{{ visitors.total }}</strong> Besuchsaufzeichnungen werden aus unserer Datenbank angezeigt.
                    </div>
                </div>
                <div class="rowjustify-content-center">
                    <div class="col-12 text-center">
                        <pagination :data="visitors" @pagination-change-page="getResults" :show-disabled="true" :limit="5">
                            <span slot="prev-nav">&lt; Vorherige</span>
                            <span slot="next-nav">Nächste &gt;</span>
                        </pagination>
                    </div>
                </div>
            </div>
            <!-- Card Body -->
            <div class="card-body table-responsive px-sm-0">
                <table class="table table-striped table-bordered">
                    <tbody>
                        <tr>
                            <th><input class="form-check-input" type="checkbox" v-model="selectVisitorsOnPage"></th>
                            <th>#</th>
                            <th>IP Adresse</th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Kontinent / Continent</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('visitors.continent', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('visitors.continent', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Land / Country</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('visitors.country', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('visitors.country', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Stadt / City</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('visitors.city', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('visitors.city', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>Admin / Besucher</th>
                            <th>Besuchte URL</th>
                            <th>Datum</th>
                            <th>Details</th>
                        </tr>

                        <tr v-for="(visitor, index) in visitors.data" :key="visitor.id" :class="(isChecked(visitor.id)) ? 'table-primary' : ''" class="align-middle">
                            <td><input class="form-check-input"  type="checkbox" :value="visitor.id" v-model="checkedVisitors"></td>
                            <td>{{ index + visitors.from }}</td>
                            <td>
                                {{ visitor.ip_address }}
                                <img class="small-flag ms-1" v-if="visitor.country_flag" :src="`/img/flags/${visitor.country_flag}`" :alt="`${visitor.country}`">
                            </td>
                            <td>{{ visitor.continent }}</td>
                            <td>{{ visitor.country }}</td>
                            <td>{{ visitor.city }}</td>
                            <td>
                                <i v-if="visitor.is_user" class="fas fa-check fa-2x text-success"></i>
                                <i v-if="!visitor.is_user" class="fas fa-times-circle fa-2x text-danger"></i>
                            </td>
                            <td>
                                <a :href="visitor.url" target="_blank">
                                    <span class="bg-primary text-white p-1">{{ visitor.url }} <i class="ms-1 fas fa-link fa-fw"></i></span>
                                </a>
                            </td>
                            <td><span class="bg-primary text-white p-1">{{ visitor.created_at | customDate }}</span></td>
                            <td class="align-middle">
                                <a target="_blank" :href="`/admin/visitors/${visitor.id}`" @click="selectRow($event)" class="btn btn-primary p-2 btnView">
                                    <i class="fas fa-eye"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- Card Footer -->
            <div class="card-footer">
                <div class="row mt-3 justify-content-center">
                    <div class="col-sm-12 text-center">
                        <pagination :data="visitors" @pagination-change-page="getResults" :show-disabled="true" :limit="5">
                            <span slot="prev-nav">&lt; Vorherige</span>
                            <span slot="next-nav">Nächste &gt;</span>
                        </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            visitors: {},
            customPaginate: 10,
            search: '',
            checkedVisitors: [],
            selectVisitorsOnPage: false,
            selectAll: false,
            sortDirection: 'DESC',
            sortField: 'created_at',
            exportUrl: '',
            selectedCity: 0,
            cities: [],
            selectedCountry: 0,
            countries: [],
            selectedContinent: 0,
            continents: [],
            userType: '',
            exportUrl: '',
        }
    },
    watch: {
        // here value parameters are the ones that come from the inputs that were bind with the data above
        customPaginate: function(value) {
            this.getResults();
        },
        search: function(value) {
            this.getResults();
        },
        selectedCity: function(value) {
            this.getResults();
        },
        selectedCountry: function(value) {
            this.getResults();
        },
        selectedContinent: function(value) {
            this.getResults();
        },
        userType: function(value) {
            this.getResults();
        },
        selectVisitorsOnPage: function(value) {
            this.checkedVisitors = [];     // in any case empty the checkboxes
            if(value) {
                this.visitors.data.forEach(visitor => {
                    this.checkedVisitors.push(visitor.id);
                });
            } else {
                this.selectAll = false;
            }
        },
        checkedVisitors: function(value) {
            this.exportUrl = `/admin/visitors/export/${this.checkedVisitors}`;
        }
    },
    methods: {
        selectRow(event) {
            let currentRow = event.target;

            while(currentRow.tagName != 'TR') {
                currentRow = currentRow.parentElement;
            }

            document.addEventListener('click', (event) => {
                if(event.target.classList.contains('btnView') || event.target.classList.contains('fa-eye')){
                    // Here we are checking if currentRow variable is set or not because click event is
                    // coming both button vuejs click event and document event listener
                    if(currentRow && !currentRow.classList.contains('table-primary')) {
                        currentRow.classList.add('table-primary');
                    }    
                } else {
                    // find all trs with the class name table-primary and remove the class
                    document.querySelectorAll('tr.table-primary').forEach((element) => {
                        element.classList.remove('table-primary');
                    });
                    currentRow = '';
                }
            });
        },
        getVisitorContinents() {
            axios.get(`/admin/get-all-visitor-continents`)
                .then((response) => {
                    this.continents = this.removeEmptyFromArray(response.data);
                });
        },
        getVisitorCountriesByContinent(selectedContinent) {
            axios.get(`/admin/get-visitor-countries-by-continent/${selectedContinent}`)
                .then((response) => {
                    this.countries = this.removeEmptyFromArray(response.data);
                });
        },
        getVisitorCitiesByCountry(selectedCountry) {
            axios.get(`/admin/get-visitor-cities-by-country/${selectedCountry}`)
                .then((response) => {
                    this.cities = this.removeEmptyFromArray(response.data);
                });
        },
        removeEmptyFromArray(customArr) {
            let newArray = [];
            for(let index=0; index<customArr.length; index++) {
                if(customArr[index]) {
                    newArray.push(customArr[index]);
                }
            }
            return newArray;
        },
        getResults(page=1) {
            axios.get(`/admin/get-visitors?page=${page}&paginate=${this.customPaginate}&query=${this.search}&selectedContinent=${this.selectedContinent}&selectedCountry=${this.selectedCountry}&selectedCity=${this.selectedCity}&userType=${this.userType}&sortDirection=${this.sortDirection}&sortField=${this.sortField}`)
                .then((response) => {
                    this.visitors = response.data;
                });
        },
        selectAllVisitors() {
            // get all the ids of the visitors by hitting an endpoint
            axios.get('/admin/visitors/get-all-visitors')
                .then((response) => {
                    this.checkedVisitors = response.data;
                    this.selectAll = true;
                });
        },
        deSelectAllManufacturers() {
            this.checkedVisitors = [];
            this.selectAll = false;
        },
        isChecked(visitorId) {
            return this.checkedVisitors.includes(visitorId);
        },
        changeSort(field, direction) {
            if(this.sortField != field || this.sortDirection != direction) {
                if(this.sortDirection != direction) {
                    this.sortDirection = direction;
                }
                if(this.sortField != field) {
                    this.sortField = field
                }
                this.getResults();
            }
        }
    },
    created() {
        // load the continents dropdown
        this.getVisitorContinents();
        // load the countries dropdown
        this.getVisitorCountriesByContinent(this.selectedContinent);
        // load the cities dropdown
        this.getVisitorCitiesByCountry(this.selectedCountry);
        // get all visitors
        this.getResults();
    }
}
</script>

<style scoped>
th {
    font-size: .9rem;
}

tr {
    font-size: .8rem;
}
</style>