require('./bootstrap');

import Vue from 'vue';

// Vue Toast Notification
import VueToast from 'vue-toast-notification';
// import one of the available themes
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast, {
    position: 'top-right'
});

// Global Filter Functions
Vue.filter('customDate', function(dateTime) {
    let myDate = new Date(dateTime),
        month = '' + (myDate.getMonth() + 1),
        day = '' + myDate.getDate(),
        year = myDate.getFullYear(),
        hour = '' + myDate.getHours(),
        minute = '' + myDate.getMinutes(),
        second = '' + myDate.getSeconds();
    
    // customize the incoming info
    if(month.length < 2) {
        month = `0${month}`;
    }
    if(day.length < 2) {
        day = `0${day}`;
    }
    if(hour.length < 2) {
        hour = `0${hour}`;
    }
    if(minute.length < 2) {
        minute = `0${minute}`;
    }
    if(second.length < 2) {
        second = `0${second}`;
    }

    myDate = `${[day, month, year].join('/')} ${[hour, minute, second].join(':')}`;

    return myDate;
});

Vue.component('antigentests-listing', require('./components/AntigentestsListing.vue').default);
Vue.component('admin-antigentests-listing', require('./components/AdminAntigentestsListing.vue').default);
Vue.component('admin-manufacturers-listing', require('./components/AdminManufacturersListing.vue').default);
Vue.component('admin-cities-listing', require('./components/AdminCitiesListing.vue').default);
Vue.component('admin-countries-listing', require('./components/AdminCountriesListing.vue').default);
Vue.component('admin-visitors-listing', require('./components/AdminVisitorsListing.vue').default);
Vue.component('admin-pages-listing', require('./components/AdminPagesListing.vue').default);
Vue.component('pagination', require('laravel-vue-pagination'));

const app = new Vue({
    el: '#app',
});

require('./scripts');