<template>
<div class="container py-4">
    <div class="row">
        <h2 class="text-center">Länderliste</h2>
        <div class="card mb-3">
            <div class="card-header">
                <div class="row mb-3">
                    <div class="col-lg-2 col-md-4 col-12">
                        <div class="me-lg-4 me-0">
                            <label>Pro Seite</label>
                            <select v-model="customPaginate" class="form-select">
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-10 col-md-8 col-12">
                        <label>Search / Suchen</label>
                        <input 
                            type="search" 
                            v-model="search" 
                            class="form-control" 
                            placeholder="Suchen Sie nach Land">
                    </div>
                </div>

                <div class="row mb-3 align-items-center">
                    <div class="col-md-2 col-12 text-md-start mb-2">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Mit geprüft ({{ checkedCountries.length }})
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a onclick="confirm('Möchten Sie die ausgewählten Einträge wirklich löschen?') || event.stopImmediatePropagation()" class="dropdown-item" href="#" @click.prevent="deleteRecords">Löschen</a>
                                </li>
                                <li><a class="dropdown-item" :href="exportUrl">Exportieren</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-7 col-12 mb-2">
                        <span v-if="selectAll">
                            Sie haben alle <strong>{{ checkedCountries.length }}</strong> Länder ausgewählt.
                            <a class="ms-2" href="#" @click.prevent="deSelectAllCountries">Alle abwählen</a>
                        </span>
                        <div v-else>
                            <span>
                                Sie wählen derzeit <strong>{{ checkedCountries.length }}</strong> Länder aus.
                            </span>
                            <span>
                                Alle <strong>{{ countries.total }}</strong> auswählen? 
                                <a class="ms-2" href="#" @click.prevent="selectAllCountries">Alles auswählen</a>
                            </span>
                        </div>
                    </div>

                    <div class="col-md-3 col-12 text-md-end">
                        <a href="/admin/countries/create" class="btn btn-primary">
                            Land hinzufügen <i class="fas fa-plus ms-2"></i>
                        </a>
                    </div>
                </div>

            </div>

            <div class="card-body table-responsive px-sm-0">
                <table class="table table-striped table-bordered">
                    <tbody>
                        <tr>
                            <th><input class="form-check-input" type="checkbox" v-model="selectCountriesOnPage"></th>
                            <th>#</th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Land / Country</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('name', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('name', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Land / Country Isocode</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('iso', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('iso', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Flag / Flagge</span>
                                </div>
                            </th>
                            <th>Werkzeuge</th>
                        </tr>

                        <tr v-for="(country, index) in countries.data" :key="country.id" :class="(isChecked(country.id) || currentCountryId == country.id) ? 'table-primary' : ''">
                            <td><input class="form-check-input"  type="checkbox" :value="country.id" v-model="checkedCountries"></td>
                            <td class="align-middle">{{ index + countries.from }}</td>
                            <td class="align-middle">{{ country.name }}</td>
                            <td class="align-middle">{{ country.iso }}</td>
                            <td><img v-if="country.flag" class="flag" :src="`/img/flags/${country.flag}`" :alt="`${country.name}`"></td>
                            <td class="align-middle">
                                <div class="d-flex">
                                    <button onclick="confirm('Möchten Sie diesen Eintrag wirklich löschen?') || event.stopImmediatePropagation()" class="btn btn-danger me-1 p-2" @click="deleteRecord(country.id)"><i class="fas fa-trash"></i></button>
                                    <a v-if="search" :href="`/admin/countries/${country.id}?q=${search}`" class="btn btn-primary me-1 p-2">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <a v-if="!search" :href="`/admin/countries/${country.id}?current_page=${countries.current_page}`" class="btn btn-primary me-1 p-2">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <a v-if="search" :href="`/admin/countries/edit/${country.id}?q=${search}`" class="btn btn-edit p-2">
                                        <i class="fas fa-pencil-alt"></i>
                                    </a>
                                    <a v-if="!search" :href="`/admin/countries/edit/${country.id}?current_page=${countries.current_page}`" class="btn btn-edit p-2">
                                        <i class="fas fa-pencil-alt"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="card-footer">
                <div class="row mt-3 justify-content-center">
                    <div class="col-sm-12 text-center">
                        <pagination :limit="5" :data="countries" @pagination-change-page="getResults" :show-disabled="true">
                            <span slot="prev-nav">&lt; Vorherige</span>
                            <span slot="next-nav">Nächste &gt;</span>
                        </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: [
        'currentCountryId',
    ],

    data() {
        return {
            customPaginate: 10,
            search: '',
            checkedCountries: [],
            selectCountriesOnPage: false,
            selectAll: false,
            sortDirection: 'ASC',
            sortField: 'countries.name',
            exportUrl: '',
            countries: {},
        }
    },
    watch: {
        // here value parameters are the ones that come from the inputs that were bind with the data above
        customPaginate: function(value) {
            this.getResults();
        },
        search: function(value) {
            this.getResults();
        },
        selectCountriesOnPage: function(value) {
            this.checkedCountries = [];     // in any case empty the checkboxes
            if(value) {
                this.countries.data.forEach(country => {
                    this.checkedCountries.push(country.id);
                });
            } else {
                this.selectAll = false;
            }
        },
        checkedCountries: function(value) {
            this.exportUrl = `/admin/countries/export/${this.checkedCountries}`;
        }
    },
    methods: {
        getResults(page=1) {
            axios.get(`/admin/get-countries?page=${page}&paginate=${this.customPaginate}&query=${this.search}&sortDirection=${this.sortDirection}&sortField=${this.sortField}`)
                .then((response) => {
                    this.countries = response.data;
                });
        },
        isChecked(countryId) {
            if(this.currentCountryId && !this.checkedCountries.includes(this.currentCountryId)) {
                window.location.href = '/admin/countries';
            } else {
                return this.checkedCountries.includes(countryId);
            }
        },
        selectAllCountries() {
            // get all the ids of the countries by hitting an endpoint which is /admin/
            axios.get('/admin/countries/get-all-countries')
                .then((response) => {
                    this.checkedCountries = response.data;
                    this.selectAll = true;
                });
        },
        deSelectAllCountries() {
            this.checkedCountries = [];
            this.selectAll = false;
        },
        changeSort(field, direction) {
            if(this.sortField != field || this.sortDirection != direction) {
                if(this.sortDirection != direction) {
                    this.sortDirection = direction;
                }
                if(this.sortField != field) {
                    this.sortField = field
                }
                this.getResults();
            }
        },
        // Delete only one record
        deleteRecord(countryId) {
            axios.delete(`/admin/countries/delete-record/${countryId}`)
            .then((response) => {
                if(response.data.status) {
                    this.getResults();
                    this.$toast.success(response.data.message);
                } else {
                    this.$toast.error(response.data.message);
                }
            });
        },
        // Delete multiple records
        deleteRecords() {
            axios.delete(`/admin/countries/mass-delete/${this.checkedCountries}`)
                .then((response) => {
                    // we are catching the status code 204 which means no content without error
                    // means records deleted successfully
                    if(response.status == 204) {
                        this.getResults();
                        this.$toast.success('Ausgewählte Länder erfolgreich gelöscht!');
                        this.checkedCountries = [];
                    } else {
                        this.$toast.error(response.data.message);
                    }
                });
        },
    },
    created() {
        // get all tests when the component first loaded
        if(this.currentCountryId) {
            this.checkedCountries.push(this.currentCountryId);
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            if(params.get('current_page')) {
                this.getResults(params.get('current_page'));
            } else if(params.get('q')) {
                this.search = params.get('q');
            } else {
                this.getResults();
            }
        } else 
        {
            this.getResults();
        }
    }
}
</script>

<style scoped>
th {
    font-size: .9rem;
}

tr {
    font-size: .9rem;
    text-align: center;
}
</style>