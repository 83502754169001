document.addEventListener("DOMContentLoaded", (event) => {
    // Create for country/city dependent dropdown
    // Vanilla Javascript without Vue integration. Used in create.blade.php
    // This code for create and edit form dependent selects, have been rewritten
    // for user friendliness.
    const countryInput = document.getElementById('country_id');
    const cityInput = document.getElementById('city_id');
    const manufacturerInput = document.getElementById('manufacturer_id');

    // This code will only work if there are country and city lists are together on the web page
    if(countryInput && cityInput) {
        countryInput.addEventListener('change', function(event) {
            getCitiesByCountry(event, oldSelectedCountry, oldSelectedCity, cityInput);
        });

        if(oldSelectedCountry) {
            countryInput.value = oldSelectedCountry;
            getCitiesByCountry(event, oldSelectedCountry, oldSelectedCity, cityInput);
            oldSelectedCountry = '';
        }
    }

    // This code will only work if there are city and manufacturer lists are together on the web page
    if(cityInput && manufacturerInput) {
        cityInput.addEventListener('change', function(event) {
            getManufacturersByCity(event, oldSelectedCity, oldSelectedCountry, manufacturerInput);
        });

        if(oldSelectedCity) {
            cityInput.value = oldSelectedCity;
            getManufacturersByCity(event, oldSelectedCity, oldSelectedManufacturer, manufacturerInput);
            oldSelectedCity = '';
        }
    }

    function getCitiesByCountry(event, oldCountry, oldCity, cityInput) {
        let selectedCountry = '';
        if(oldCountry) {
            selectedCountry = oldCountry;
        } else {
            selectedCountry = event.target.value;
        }
        if(selectedCountry) {
            axios.get(`/admin/cities/get-city-by-country/${selectedCountry}`)
            .then((response) => {
                const cities = response.data;
                emptySelect(cityInput);
                cityInput.disabled = false;
                const option = document.createElement('option');
                option.text = 'Stadt auswählen';
                option.value = '';
                cityInput.append(option);
                cities.forEach(element => {
                    const option = document.createElement('option');
                    option.text = element.name;
                    option.value = element.id;
                    if(oldCity && (element.id == oldCity)) {
                        option.selected = true;
                    }
                    cityInput.append(option);
                });
            });
        } else {
            cityInput.disabled = true;
            emptySelect(cityInput);
        }
    }

    function getManufacturersByCity(event, oldCity, oldManufacturer, manufacturerInput) {
        let selectedCity = '';
        if(oldCity) {
            selectedCity = oldCity;
        } else {
            selectedCity = event.target.value;
        }
        if(selectedCity) {
            axios.get(`/admin/manufacturers/get-manufacturer-by-city/${selectedCity}`)
            .then((response) => {
                const manufacturers = response.data;
                emptySelect(manufacturerInput);
                manufacturerInput.disabled = false;
                const option = document.createElement('option');
                option.text = 'Hersteller auswählen';
                option.value = '';
                manufacturerInput.append(option);
                manufacturers.forEach(element => {
                    const option = document.createElement('option');
                    option.text = element.name;
                    option.value = element.id;
                    if(oldManufacturer && (element.id == oldManufacturer)) {
                        option.selected = true;
                    }
                    manufacturerInput.append(option);
                });
            })
        } else {
            manufacturerInput.disabled = true;
            emptySelect(manufacturerInput);
        }
    }

    function emptySelect(selectInput) {
        if(selectInput.options.length > 0) {
            while (selectInput.options.length) {
                selectInput.remove(0);
            }
        }
    }

    // Antigentest create and edit form form
    const antigentestForm = document.getElementById('antigentest-form');
    const antigentestSubmitBtn = document.querySelector('#antigentest-form button[type=submit]');
    const docFileInput = document.getElementById('manual_path');
    const imageFileInput = document.getElementById('image_path');
    const pdfNotification = document.getElementById('pdf-notification');
    const imageNotification = document.getElementById('image-notification');
    // Page overlay for updating content
    const pageOverlay = document.querySelector('.page-overlay');

    // sanity check
    if(antigentestForm) {
        antigentestForm.addEventListener('submit', () => {
            antigentestSubmitBtn.disabled = true;
            antigentestSubmitBtn.innerText = 'Senden...';
            if(docFileInput.files[0]) {
                if(docFileInput.files[0].type == 'application/pdf') {
                    pdfNotification.classList.remove('d-none');
                    pageOverlay.classList.add('page-overlay--active');
                }
            }
            if(imageFileInput.files[0]) {
                imageNotification.classList.remove('d-none');
                pageOverlay.classList.add('page-overlay--active');
            }
        });
    }

    // Leoshop External Shop Link Check
    // Antigentest Create and edit forms
    const radioLeoShopYes = document.querySelector('#is_leoshop_product_yes');
    const radioLeoShopNo = document.querySelector('#is_leoshop_product_no');
    const urlExternalShop = document.querySelector('#external_shop_url');

    let currentUrl = '';
    // sanitize dom elements before adding event listener
    if(radioLeoShopYes) {
        radioLeoShopYes.addEventListener('click', () => {
            urlExternalShop.disabled = false;
            if(currentUrl){
                urlExternalShop.value = currentUrl;
            }
            urlExternalShop.required = true;
        });
    }
    if(radioLeoShopNo) {
        radioLeoShopNo.addEventListener('click', () => {
            if(urlExternalShop.value != '') {
                currentUrl = urlExternalShop.value;
            }
            urlExternalShop.value = '';
            urlExternalShop.disabled = true;
            urlExternalShop.required = false;
        });
    }

    // Because Cookie Consent is not available for backend we should still do sanitize checking
    // ### Cookie Consent Management ###
    const cookieConsentBanner = document.querySelector('#cookieConsent');
    if(cookieConsentBanner) {
        if(!localStorage.getItem('cookies_consent_fine')) {
            localStorage.setItem('cookies_consent_fine', '0');
        } else {
            if(localStorage.getItem('cookies_consent_fine') == '1') {
                let cookieConsentTimestamp = localStorage.getItem('cookies_consent_fine_timestamp');
                if(cookieConsentTimestamp) {
                    let currentTimestamp = new Date().getTime();
                    // get the time difference as day and display the cookie consent banner for 7 days
                    let timestampDifference = (currentTimestamp - cookieConsentTimestamp) / (24 * 60 * 60 * 1000);
                    if(timestampDifference >= 7) {
                        localStorage.setItem('cookies_consent_fine', '0');
                        cookieConsentBanner.removeAttribute('style');
                        if(cookieConsentBanner.classList.contains('custom-fade-out')) {
                            cookieConsentBanner.classList.remove('custom-fade-out');
                        }
                        cookieConsentBanner.classList.add('custom-fade-in');
                    } else {
                        if(cookieConsentBanner.classList.contains('custom-fade-in')) {
                            cookieConsentBanner.classList.remove('custom-fade-in');
                        }
                        cookieConsentBanner.style.display = 'none';
                    }
                }
            } else {
                // show the banner with a small animation
                cookieConsentBanner.style.opacity = '0';
                setTimeout(() => {
                    cookieConsentBanner.removeAttribute('style');
                    cookieConsentBanner.classList.add('custom-fade-in');
                }, 500);
            }
        }
    }

    // manage cookie consent banner ok button
    const cookieConsentBtn = document.querySelector('.cookieConsentOK');
    if(cookieConsentBtn) {
        cookieConsentBtn.addEventListener('click', () => {
            localStorage.setItem('cookies_consent_fine', '1');
            localStorage.setItem('cookies_consent_fine_timestamp', new Date().getTime());
            if(cookieConsentBanner.classList.contains('custom-fade-in')) {
                cookieConsentBanner.classList.remove('custom-fade-in');
            }
            cookieConsentBanner.classList.add('custom-fade-out');
        });
    }

    // manage cookie consent banner close button
    const cookieConsentCloseBtn = document.querySelector('#closeCookieConsent');
    if(cookieConsentCloseBtn) {
        cookieConsentCloseBtn.addEventListener('click', () => {
            // this time close it without setting the localstorage variable
            if(cookieConsentBanner.classList.contains('custom-fade-in')) {
                cookieConsentBanner.classList.remove('custom-fade-in');
            }
            cookieConsentBanner.classList.add('custom-fade-out');
        })
    }

    // Slugify the title that is entered via page create form
    const pageTitle = document.getElementById('title');
    const pageSlug = document.getElementById('slug');
    const checkIfHome = document.getElementById('is_homepage');
    if(pageTitle && pageSlug) {
        pageTitle.addEventListener('change', () => {
            if((checkIfHome && !checkIfHome.checked) || !checkIfHome) {
                pageSlug.value = slugify(pageTitle.value);
            }
        });

        pageSlug.addEventListener('change', () => {
            if(pageSlug.value !== '') {
                if((checkIfHome && !checkIfHome.checked) || !checkIfHome) {
                    pageSlug.value = slugify(pageSlug.value);
                } else {
                    pageSlug.value = '/';
                }
            } else {
                if((checkIfHome && !checkIfHome.checked) || !checkIfHome) {
                    pageSlug.value = slugify(pageTitle.value);
                } else {
                    pageSlug.value = '/';
                }
            }
        });
    }

    // ##### FUNCTIONS - for slugifying the title
    function slugify(text) {
        text = replaceUmlauts(text);
        return text
            .toString()                           // Cast to string (optional)
            .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
            .toLowerCase()                  // Convert the string to lowercase letters
            .trim()                          // Remove whitespace from both sides of a string (optional)
            .replace(/\s+/g, '-')            // Replace spaces with -
            .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
            .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    }

    function replaceUmlauts(str) {
    return str
        .replace(/\u00df/g, 'ss')
        .replace(/\u00e4/g, 'ae')
        .replace(/\u00f6/g, 'oe')
        .replace(/\u00fc/g, 'ue')
        .replace(/\u00c4/g, 'Ae')
        .replace(/\u00d6/g, 'Oe')
        .replace(/\u00dc/g, 'Ue');
    }

    // Character count for SEO description
    const metaDescription = document.querySelector('.meta-desc');
    const charCount = document.querySelector('.charCount');
    const countLimit = 160;
    if(metaDescription && charCount) {
        characterCount(metaDescription, charCount, countLimit);
    }

    function characterCount(textElement, charCountElement, countLimit) {
        if(textElement.value.length > countLimit) {
            charCountElement.classList.add('text-danger');
        } else {
            charCountElement.classList.add('text-success');
        }
        charCountElement.innerHTML = textElement.value.length;
        textElement.addEventListener('keyup', () => {
            if(textElement.value.length > countLimit) {
                charCountElement.classList.add('text-danger');
                if(charCountElement.classList.contains('text-success')) {
                    charCountElement.classList.remove('text-success');
                }
            } else {
                charCountElement.classList.add('text-success');
                if(charCountElement.classList.contains('text-danger')) {
                    charCountElement.classList.remove('text-danger');
                }
            }
            charCountElement.innerHTML = textElement.value.length;
        });
    }
});