<template>
<div class="container-fluid py-4">
    <div class="row">
        <h2 class="text-center py-3">{{ settingsJsonObj.heading }}</h2>
        <div class="card mb-3">
            <div class="card-header">
                <div class="row mb-3">
                    <div class="col-lg-2 col-md-4 col-6 mb-2">
                        <div class="me-lg-4 me-0">
                            <label>Pro Seite</label>
                            <select v-model="customPaginate" class="form-select form-select-sm">
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4 col-6 mb-2">
                        <div class="me-lg-4 me-0">
                            <label>Profi / Selbsttest</label>
                            <select v-model="testType" class="form-select form-select-sm">
                                <option value="">Alle</option>
                                <option value="1">Profitest</option>
                                <option value="0">Selbsttest</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4 col-6 mb-2">
                        <div class="me-lg-4 me-0">
                            <label>Cq &leq;25</label>
                            <select v-model="cq25" class="form-select form-select-sm">
                                <option value="0">Alle</option>
                                <option value="90">&GreaterEqual;90%</option>
                                <option value="80">&GreaterEqual;80%</option>
                                <option value="70">&GreaterEqual;70%</option>
                                <option value="50">&GreaterEqual;50%</option>
                                <option value="25">&GreaterEqual;25%</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4 col-6 mb-2">
                        <div class="me-lg-4 me-0">
                            <label>Cq 25-30</label>
                            <select v-model="cq25_30" class="form-select form-select-sm">
                                <option value="0">Alle</option>
                                <option value="90">&GreaterEqual;90%</option>
                                <option value="80">&GreaterEqual;80%</option>
                                <option value="70">&GreaterEqual;70%</option>
                                <option value="50">&GreaterEqual;50%</option>
                                <option value="25">&GreaterEqual;25%</option>
                                <option value="10">&GreaterEqual;10%</option>
                                <option value="5">&GreaterEqual;5%</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4 col-6 mb-2">
                        <div class="me-lg-4 me-0">
                            <label>Cq &GreaterEqual;30</label>
                            <select v-model="cq30" class="form-select form-select-sm">
                                <option value="0">Alle</option>
                                <option value="90">&GreaterEqual;90%</option>
                                <option value="80">&GreaterEqual;80%</option>
                                <option value="70">&GreaterEqual;70%</option>
                                <option value="50">&GreaterEqual;50%</option>
                                <option value="25">&GreaterEqual;25%</option>
                                <option value="10">&GreaterEqual;10%</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4 col-6 mb-2">
                        <div class="me-0">
                            <label>Gesamt- Sensitvität</label>
                            <select v-model="totalSens" class="form-select form-select-sm">
                                <option value="0">Alle</option>
                                <option value="90">&GreaterEqual;90%</option>
                                <option value="80">&GreaterEqual;80%</option>
                                <option value="70">&GreaterEqual;70%</option>
                                <option value="50">&GreaterEqual;50%</option>
                                <option value="20">&GreaterEqual;20%</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-12">
                        <input 
                            type="search" 
                            v-model="search" 
                            class="form-control" 
                            placeholder="Suchen Sie nach Testname, AT-Nr, Ref-Nr., Hersteller oder Land">
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-12">
                        Es gibt <strong>{{ antigentests.total }}</strong> Tests in unserer Datenbank.
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <pagination :limit="5" :data="antigentests" @pagination-change-page="getResults" :show-disabled="true">
                            <span slot="prev-nav">&lt; Vorherige</span>
                            <span slot="next-nav">Nächste &gt;</span>
                        </pagination>
                    </div>
                </div>
            </div>

            <div class="card-body table-responsive px-sm-0">
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>{{ settingsJsonObj.column.atNumber }}</th>
                            <th>{{ settingsJsonObj.column.testType }}</th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">{{ settingsJsonObj.column.manufacturerName }}</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('manufacturer_name', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('manufacturer_name', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">{{ settingsJsonObj.column.testName }}</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('antigen_tests.name', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('antigen_tests.name', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">{{ settingsJsonObj.column.cqLessThan25 }}</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('sens_percent_cq_low_equal25', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('sens_percent_cq_low_equal25', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">{{ settingsJsonObj.column.cqBetween2530 }}</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('sens_percent_cq_between_2530', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('sens_percent_cq_between_2530', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">{{ settingsJsonObj.column.cqMoreThan30 }}</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('sens_percent_cq_high_equal30', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('sens_percent_cq_high_equal30', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">{{ settingsJsonObj.column.totalSens }}</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('sens_total', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('sens_total', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">{{ settingsJsonObj.column.omicronEffective }}</span>
                                </div>
                            </th>
                            <th>{{ settingsJsonObj.column.tools }}</th>
                        </tr>

                        <tr v-for="(antigentest, index) in antigentests.data" :key="antigentest.id" :class="[(!antigentest.image_path && !antigentest.manual_path && !antigentest.desc_url) ? 'table-all-needed-warning' : '', (!antigentest.image_path && !antigentest.manual_path && antigentest.desc_url) ? 'table-image-instruction-warning' : '', (!antigentest.image_path && antigentest.manual_path) ? 'table-image-warning' : '', (antigentest.image_path && !antigentest.manual_path) ? 'table-instruction-warning' : '', (antigentest.image_path && antigentest.manual_path && !antigentest.desc_url) ? 'table-prod-comp-link-warning' : '']">
                            <td>{{ index + antigentests.from }}</td>
                            <td>{{ antigentest.test_no }}</td>
                            <td>
                                <span v-if="antigentest.is_professional">Profitest <i class="fas fa-medkit fa-fw ms-1 text-primary"></i></span>
                                <span v-if="!antigentest.is_professional">Selbsttest <i class="fas fa-user fa-fw ms-1 text-primary"></i></span>
                            </td>
                            <td>
                                {{ antigentest.manufacturer_name }} <img class="small-flag ms-1" v-if="antigentest.country_flag" :src="`/img/flags/${antigentest.country_flag}`" :alt="`${antigentest.name}`">
                            </td>
                            <td>{{ antigentest.name }}</td>
                            <td><span class="bg-primary p-1 text-white">{{ antigentest.sens_percent_cq_low_equal25 }}%</span></td>
                            <td><span class="bg-primary p-1 text-white">{{ antigentest.sens_percent_cq_between_2530 }}%</span></td>
                            <td><span class="bg-primary p-1 text-white">{{ antigentest.sens_percent_cq_high_equal30 }}%</span></td>
                            <td><span class="bg-primary p-1 text-white">{{ antigentest.sens_total }}%</span></td>
                            <td>
                                <i v-if="antigentest.is_omicron_valid" class="fas fa-check fa-2x text-success"></i>
                                <i v-if="!antigentest.is_omicron_valid" class="fas fa-times-circle fa-2x text-danger"></i>
                            </td>
                            <td class="bg-white align-middle">
                                <div class="d-flex">
                                    <a @click="selectRow($event)" target="_blank" :href="`/antigentests/${antigentest.slug}`" class="btn btn-primary p-2 btnView">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <a v-if="antigentest.is_leoshop_product" target="_blank" :href="antigentest.external_shop_url">
                                        <img src="/favicon.png" :alt="antigentest.name" class="flag">
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="card-footer">
                <div class="row mt-3 justify-content-center">
                    <div class="col-sm-12">
                        <pagination :limit="5" :data="antigentests" @pagination-change-page="getResults" :show-disabled="true">
                            <span slot="prev-nav">&lt; Vorherige</span>
                            <span slot="next-nav">Nächste &gt;</span>
                        </pagination>
                        <div class="table-explanation">
                            <h6>
                                Abkürzungen und Zeichenerklärung / Abbreviations and explanation of symbols:
                            </h6>
                            <p class="small">
                                Cq &#61; Quantifizierungszyklus / quantitative cycle<br>
                                n.a. &#61; nicht verfügbar / not available<br>
                                * &#61; Ref-Nr. oder Katalog-Nummer wie bei Einreichung zur Evaluierung / Ref. No. or catalog number as submitted for evaluation<br>
                                N &#61; Nukleocapsid Protein / nucleocapsid protein<br>
                                S &#61; Spike Protein / spike protein
                            </p>
                            <hr>
                            <h6>Tabellenfarbcodes</h6>
                            <div class="color-legend small">
                                <span class="bg-all-needed-warning d-inline-block"></span> &#61; Kein Bild, keine Gebrauchsanweisung und kein Firmen-/Produktlink<br>
                                <span class="bg-image-instruction-warning d-inline-block"></span> &#61; Kein Bild und keine Gebrauchsanweisung. <br>
                                <span class="bg-image-warning d-inline-block"></span> &#61; Kein Bild<br>
                                <span class="bg-instruction-warning d-inline-block"></span> &#61; Keine Gebrauchsanweisung<br>
                                <span class="bg-prod-comp-link-warning d-inline-block"></span> &#61; Kein Firmen-/Produktlink
                            </div>
                            <hr>
                            <p class="small fw-bold">Quelle: <a href="https://www.pei.de/DE/home/home-node.html" target="_blank">Paul-Erlich-Institut</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: [
        'settingsJson',
    ],
    data() {
        return {
            antigentests: {},
            customPaginate: 10,
            search: '',
            cq30: 0,
            cq25_30: 0,
            cq25: 0,
            totalSens: 0,
            testType: '',
            sortDirection: 'ASC',
            sortField: 'manufacturer_name',
        }
    },
    watch: {
        // here value parameters are the ones that come from the inputs that were bind with the data above
        customPaginate: function(value) {
            this.getResults();
        },
        search: function(value) {
            this.getResults();
        },
        cq30: function(value) {
            this.getResults();
        },
        cq25_30: function(value) {
            this.getResults();
        },
        cq25: function(value) {
            this.getResults();
        },
        totalSens: function(value) {
            this.getResults();
        },
        testType: function(value) {
            this.getResults();
        },
    },
    methods: {
        selectRow(event) {
            let currentRow = event.target;
            console.log(currentRow);

            while(currentRow.tagName != 'TR') {
                currentRow = currentRow.parentElement;
            }

            document.addEventListener('click', (event) => {
                if(event.target.classList.contains('btnView') || event.target.classList.contains('fa-eye')){
                    // Here we are checking if currentRow variable is set or not because click event is
                    // coming both button vuejs click event and document event listener
                    if(currentRow && !currentRow.classList.contains('table-row-selected')) {
                        currentRow.classList.add('table-row-selected');
                    }    
                } else {
                    // find all trs with the class name table-row-selected and remove the class
                    document.querySelectorAll('tr.table-row-selected').forEach((element) => {
                        element.classList.remove('table-row-selected');
                    });
                    currentRow = '';
                }
            });
        },
        getResults(page=1) {
            axios.get(`/get-all-antigentests?page=${page}&paginate=${this.customPaginate}&query=${this.search}&cq30=${this.cq30}&cq25_30=${this.cq25_30}&cq25=${this.cq25}&totalSens=${this.totalSens}&testType=${this.testType}&sortDirection=${this.sortDirection}&sortField=${this.sortField}`)
                .then((response) => {
                    this.antigentests = response.data;
                });
        },
        changeSort(field, direction) {
            if(this.sortField != field || this.sortDirection != direction) {
                if(this.sortDirection != direction) {
                    this.sortDirection = direction;
                }
                if(this.sortField != field) {
                    this.sortField = field
                }
                this.getResults();
            }
        }
    },
    computed: {
        // Use this computed object variable to parse the string prop named settingsJson
        // to settingsJsonObj to use it in html template easily
        // This is the accepted Vue.js way.
        settingsJsonObj: function() {
            return JSON.parse(this.settingsJson);
        }
    },
    mounted() {
        // get all tests
        this.getResults();
    }
}
</script>

<style scoped>
th {
    font-size: .9rem;
}

tr {
    font-size: .8rem;
    text-align: center;
}
</style>