<template>
<div class="container py-4">
    <div class="row">
        <h2 class="text-center">Hersteller Liste</h2>
        <div class="card mb-3">
            <div class="card-header">
                <div class="row mb-3">
                    <div class="col-lg-2 col-md-4 col-6">
                        <div class="me-lg-4 me-0">
                            <label>Pro Seite</label>
                            <select v-model="customPaginate" class="form-select">
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4 col-6">
                        <div class="me-lg-4 me-0">
                            <label>Land / Country</label>
                            <select @change="getCitiesByCountry(selectedCountry)" v-model="selectedCountry" class="form-select">
                                <option value="0">Alle</option>
                                <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4 col-12">
                        <div class="me-lg-4 me-0">
                            <label>Stadt / City</label>
                            <select v-model="selectedCity" class="form-select">
                                <option value="0">Alle</option>
                                <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-4 col-12">
                        <label>Search / Suchen</label>
                        <input 
                            type="search" 
                            v-model="search" 
                            class="form-control" 
                            placeholder="Suchen Sie nach Hersteller, City oder Land">
                    </div>
                </div>

                <div class="row mb-3 align-items-center">
                    <div class="col-md-2 col-12 text-md-start mb-2">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Mit geprüft ({{ checkedManufacturers.length }})
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a onclick="confirm('Möchten Sie die ausgewählten Einträge wirklich löschen?') || event.stopImmediatePropagation()" class="dropdown-item" href="#" @click.prevent="deleteRecords">Löschen</a>
                                </li>
                                <li><a class="dropdown-item" :href="exportUrl">Exportieren</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-7 col-12 mb-2">
                        <span v-if="selectAll">
                            Sie haben alle <strong>{{ checkedManufacturers.length }}</strong> Hersteller ausgewählt.
                            <a class="ms-2" href="#" @click.prevent="deSelectAllManufacturers">Alle abwählen</a>
                        </span>
                        <div v-else>
                            <span>
                                Sie wählen derzeit <strong>{{ checkedManufacturers.length }}</strong> Hersteller aus.
                            </span>
                            <span>
                                Alle <strong>{{ manufacturers.total }}</strong> auswählen? 
                                <a class="ms-2" href="#" @click.prevent="selectAllManufacturers">Alles auswählen</a>
                            </span>
                        </div>
                    </div>

                    <div class="col-md-3 col-12 text-md-end">
                        <a href="/admin/manufacturers/create" class="btn btn-primary btn-block">
                            Hersteller hinzufügen <i class="fas fa-plus ms-2"></i>
                        </a>
                    </div>
                </div>

            </div>

            <div class="card-body table-responsive px-sm-0">
                <table class="table table-striped table-bordered">
                    <tbody>
                        <tr>
                            <th><input class="form-check-input" type="checkbox" v-model="selectManufacturersOnPage"></th>
                            <th>#</th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Hersteller / Manufacturer</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('manufacturers.name', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('manufacturers.name', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Stadt / City</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('city_name', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('city_name', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Land / Country</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('country_name', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('country_name', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Land / Country Isocode</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('country_isocode', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('country_isocode', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>Werkzeuge</th>
                        </tr>

                        <tr v-for="(manufacturer, index) in manufacturers.data" :key="manufacturer.id" :class="(isChecked(manufacturer.id) || currentManufacturerId == manufacturer.id) ? 'table-primary' : ''">
                            <td><input class="form-check-input"  type="checkbox" :value="manufacturer.id" v-model="checkedManufacturers"></td>
                            <td>{{ index + manufacturers.from }}</td>
                            <td>{{ manufacturer.name }}</td>
                            <td>{{ manufacturer.city_name }}</td>
                            <td>{{ manufacturer.country_name }}</td>
                            <td>{{ manufacturer.country_isocode }}</td>
                            <td class="align-middle">
                                <div class="d-flex">
                                    <button onclick="confirm('Möchten Sie diesen Eintrag wirklich löschen?') || event.stopImmediatePropagation()" class="btn btn-danger me-1 p-2" @click="deleteRecord(manufacturer.id)"><i class="fas fa-trash"></i></button>
                                    <a v-if="search" :href="`/admin/manufacturers/${manufacturer.id}?q=${search}`" class="btn btn-primary me-1 p-2">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <a v-if="!search" :href="`/admin/manufacturers/${manufacturer.id}?current_page=${manufacturers.current_page}`" class="btn btn-primary me-1 p-2">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <a v-if="search" :href="`/admin/manufacturers/edit/${manufacturer.id}?q=${search}`" class="btn btn-edit p-2">
                                        <i class="fas fa-pencil-alt"></i>
                                    </a>
                                    <a v-if="!search" :href="`/admin/manufacturers/edit/${manufacturer.id}?current_page=${manufacturers.current_page}`" class="btn btn-edit p-2">
                                        <i class="fas fa-pencil-alt"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="card-footer">
                <div class="row mt-3 justify-content-center">
                    <div class="col-sm-12 text-center">
                        <pagination :limit="5" :data="manufacturers" @pagination-change-page="getResults" :show-disabled="true">
                            <span slot="prev-nav">&lt; Vorherige</span>
                            <span slot="next-nav">Nächste &gt;</span>
                        </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: [
        'currentManufacturerId',
    ],
    data() {
        return {
            manufacturers: {},
            customPaginate: 10,
            search: '',
            checkedManufacturers: [],
            selectManufacturersOnPage: false,
            selectAll: false,
            sortDirection: 'ASC',
            sortField: 'manufacturers.name',
            exportUrl: '',
            selectedCity: 0,
            cities: {},
            selectedCountry: 0,
            countries: {},
        }
    },
    watch: {
        // here value parameters are the ones that come from the inputs that were bind with the data above
        customPaginate: function(value) {
            this.getResults();
        },
        search: function(value) {
            this.getResults();
        },
        selectedCity: function(value) {
            this.getResults();
        },
        selectedCountry: function(value) {
            this.getResults();
        },
        selectManufacturersOnPage: function(value) {
            this.checkedManufacturers = [];     // in any case empty the checkboxes
            if(value) {
                this.manufacturers.data.forEach(manufacturer => {
                    this.checkedManufacturers.push(manufacturer.id);
                });
            } else {
                this.selectAll = false;
            }
        },
        checkedManufacturers: function(value) {
            this.exportUrl = `/admin/manufacturers/export/${this.checkedManufacturers}`;
        }
    },
    methods: {
        getAllCountriesWithCities() {
            axios.get(`/admin/get-all-countries-with-cities`)
                .then((response) => {
                    this.countries = response.data;
                });
        },
        getCitiesByCountry(selectedCountry) {
            axios.get(`/admin/cities/get-city-by-country/${selectedCountry}`)
                .then((response) => {
                    this.cities = response.data;
                });
        },
        getResults(page=1) {
            axios.get(`/admin/get-manufacturers?page=${page}&paginate=${this.customPaginate}&query=${this.search}&selectedCountry=${this.selectedCountry}&selectedCity=${this.selectedCity}&sortDirection=${this.sortDirection}&sortField=${this.sortField}`)
                .then((response) => {
                    this.manufacturers = response.data;
                });
        },
        deleteRecord(manufacturerId) {
            axios.delete(`/admin/manufacturers/delete-record/${manufacturerId}`)
            .then((response) => {
                if(response.data.status) {
                    this.getResults();
                    this.$toast.success(response.data.message);
                } else {
                    this.$toast.error(response.data.message);
                }
            });
        },
        deleteRecords() {
            axios.delete(`/admin/manufacturers/mass-delete/${this.checkedManufacturers}`)
                .then((response) => {
                    // we are catching the status code 204 which means no content without error
                    if(response.status == 204) {
                        this.getResults();
                        this.$toast.success('Ausgewählte Herstellers erfolgreich gelöscht!');
                        this.checkedManufacturers = [];
                    } else {
                        this.$toast.error(response.data.message);
                    }
                });
        },
        selectAllManufacturers() {
            // get all the ids of the manufacturers by hitting an endpoint
            axios.get('/admin/manufacturers/get-all-manufacturers')
                .then((response) => {
                    this.checkedManufacturers = response.data;
                    this.selectAll = true;
                });
        },
        deSelectAllManufacturers() {
            this.checkedManufacturers = [];
            this.selectAll = false;
        },
        isChecked(manufacturerId) {
            if(this.currentManufacturerId && !this.checkedManufacturers.includes(this.currentManufacturerId)) {
                window.location.href = '/admin/manufacturers';
            } else {
                return this.checkedManufacturers.includes(manufacturerId);
            }
        },
        changeSort(field, direction) {
            if(this.sortField != field || this.sortDirection != direction) {
                if(this.sortDirection != direction) {
                    this.sortDirection = direction;
                }
                if(this.sortField != field) {
                    this.sortField = field
                }
                this.getResults();
            }
        }
    },
    created() {
        this.getAllCountriesWithCities();
        this.getCitiesByCountry(this.selectedCountry);
        // get all tests when the component first loaded
        if(this.currentManufacturerId) {
            this.checkedManufacturers.push(this.currentManufacturerId);
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            if(params.get('current_page')) {
                this.getResults(params.get('current_page'));
            } else if(params.get('q')) {
                this.search = params.get('q');
            } else {
                this.getResults();
            }
        } else 
        {
            this.getResults();
        }
    }
}
</script>

<style scoped>
th {
    font-size: .9rem;
}

tr {
    font-size: .8rem;
    text-align: center;
}
</style>