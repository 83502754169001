<template>
<div class="container py-4">
    <div class="row">
        <h2 class="text-center">Statische Seitenliste</h2>
        <div class="card mb-3">
            <div class="card-header">
                <div class="row mb-3">
                    <div class="col-lg-2 col-md-4 col-12">
                        <label>Status</label>
                        <select v-model="pageStatus" class="form-select">
                            <option value="">Alle</option>
                            <option value="public">Öffentlich</option>
                            <option value="private">Privat</option>
                        </select>
                    </div>
                    <div class="col-lg-10 col-md-8 col-12">
                        <label>Search / Suchen</label>
                        <input 
                            type="search" 
                            v-model="search" 
                            class="form-control" 
                            placeholder="Suchen Sie nach Titel und Inhalt">
                    </div>
                </div>

                <div class="row mb-3 align-items-center">
                    <div class="col-md-2 col-12 text-md-start mb-2">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Mit geprüft ({{ checkedPages.length }})
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a onclick="confirm('Möchten Sie die ausgewählten Einträge wirklich löschen?') || event.stopImmediatePropagation()" class="dropdown-item" href="#" @click.prevent="deleteRecords">Löschen</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-7 col-12 mb-2">
                        <span v-if="selectAll">
                            Sie haben alle <strong>{{ checkedPages.length }}</strong> Seiten ausgewählt.
                            <a class="ms-2" href="#" @click.prevent="deSelectAllPages">Alle abwählen</a>
                        </span>
                        <div v-else>
                            <span>
                                Sie wählen derzeit <strong>{{ checkedPages.length }}</strong> Seiten aus.
                            </span>
                            <span>
                                Alle <strong>{{ pages.total }}</strong> auswählen? 
                                <a class="ms-2" href="#" @click.prevent="selectAllPages">Alles auswählen</a>
                            </span>
                        </div>
                    </div>

                    <div class="col-md-3 col-12 text-md-end">
                        <a href="/admin/pages/create" class="btn btn-primary">
                            Seite hinzufügen <i class="fas fa-plus ms-2"></i>
                        </a>
                    </div>
                </div>

            </div>

            <div class="card-body table-responsive px-sm-0">
                <table class="table table-striped table-bordered">
                    <tbody>
                        <tr>
                            <th><input class="form-check-input" type="checkbox" v-model="selectPagesOnPage"></th>
                            <th>#</th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Titel</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('title', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('title', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Slug</span>
                                    <div class="d-flex flex-column sort-column">
                                        <i @click="changeSort('slug', 'DESC')" class="fas fa-sort-up"></i>
                                        <i @click="changeSort('slug', 'ASC')" class="fas fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Status</span>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Reihenfolge</span>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex justify-content-center">
                                    <span class="me-3">Datum</span>
                                </div>
                            </th>
                            <th>Werkzeuge</th>
                        </tr>

                        <tr v-for="(page, index) in pages.data" :key="page.id" :class="(isChecked(page.id) || currentPageId == page.id) ? 'table-primary' : ''">
                            <td class="align-middle"><input class="form-check-input"  type="checkbox" :value="page.id" v-model="checkedPages"></td>
                            <td class="align-middle">{{ index + pages.from }}</td>
                            <td class="align-middle">{{ page.title }}</td>
                            <td class="align-middle">{{ page.slug }}</td>
                            <td class="align-middle">{{ page.status }}</td>
                            <td class="align-middle">{{ page.menu_order }}</td>
                            <td class="align-middle">{{ page.created_at | customDate }}</td>
                            <td class="align-middle">
                                <div class="d-flex">
                                    <button onclick="confirm('Möchten Sie diesen Eintrag wirklich löschen?') || event.stopImmediatePropagation()" class="btn btn-danger me-1 p-2" @click="deleteRecord(page.id)"><i class="fas fa-trash"></i></button>
                                    <a v-if="search" :href="`/admin/pages/${page.id}?q=${search}`" class="btn btn-primary me-1 p-2">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <a v-if="!search" :href="`/admin/pages/${page.id}?current_page=${pages.current_page}`" class="btn btn-primary me-1 p-2">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <a v-if="search" :href="`/admin/pages/edit/${page.id}?q=${search}`" class="btn btn-edit p-2">
                                        <i class="fas fa-pencil-alt"></i>
                                    </a>
                                    <a v-if="!search" :href="`/admin/pages/edit/${page.id}?current_page=${pages.current_page}`" class="btn btn-edit p-2 me-1">
                                        <i class="fas fa-pencil-alt"></i>
                                    </a>
                                    <a :href="(page.slug == '/') ? '/' : `/${page.slug}`" target="_blank" class="btn btn-primary p-2 me-1">
                                        <i class="fas fa-external-link-alt"></i>
                                    </a>
                                    <img v-if="(page.meta_description && page.meta_title)" src="/img/magnifying-glass-ok.png" alt="SEO-Info vollständig" class="flag" data-bs-toggle="tooltip" data-bs-placement="top" data-bs- html="true" title="SEO-Info vollständig">

                                    <img v-if="(!page.meta_description || !page.meta_title)" src="/img/magnifying-glass-error.png" alt="SEO-Info nicht vollständig" class="flag" data-bs-toggle="tooltip" data-bs-placement="top" data-bs- html="true" title="SEO-Info nicht vollständig">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="card-footer">
                <div class="row mt-3 justify-content-center">
                    <div class="col-sm-12 text-center">
                        <pagination :limit="5" :data="pages" @pagination-change-page="getResults" :show-disabled="true">
                            <span slot="prev-nav">&lt; Vorherige</span>
                            <span slot="next-nav">Nächste &gt;</span>
                        </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
// Tooltip should be added from Bootstrap module
import {Tooltip} from 'bootstrap';
new Tooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});

export default {
    props: [
        'currentPageId',
    ],
    data() {
        return {
            search: '',
            checkedPages: [],
            selectPagesOnPage: false,
            selectAll: false,
            sortDirection: 'ASC',
            sortField: 'pages.title',
            pageStatus: '',
            pages: {},
        }
    },
    watch: {
        search: function(value) {
            this.getResults();
        },
        selectPagesOnPage: function(value) {
            this.checkedPages = [];     // in any case empty the checkboxes
            if(value) {
                this.pages.data.forEach(page => {
                    this.checkedPages.push(page.id);
                });
            } else {
                this.selectAll = false;
            }
        },
    },
    methods: {
        getResults(page=1) {
            axios.get(`/admin/get-pages?page=${page}&query=${this.search}&sortDirection=${this.sortDirection}&sortField=${this.sortField}`)
                .then((response) => {
                    this.pages = response.data;
                });
        },
        isChecked(pageId) {
            if(this.currentPageId && !this.checkedPages.includes(this.currentPageId)) {
                window.location.href = '/admin/pages';
            } else {
                return this.checkedPages.includes(pageId);
            }
        },
        selectAllPages() {
            // get all the ids of the pages by hitting an endpoint which is /admin/pages/get-all-pages
            axios.get('/admin/pages/get-all-pages')
                .then((response) => {
                    this.checkedPages = response.data;
                    this.selectAll = true;
                });
        },
        deSelectAllPages() {
            this.checkedPages = [];
            this.selectAll = false;
        },
        changeSort(field, direction) {
            if(this.sortField != field || this.sortDirection != direction) {
                if(this.sortDirection != direction) {
                    this.sortDirection = direction;
                }
                if(this.sortField != field) {
                    this.sortField = field
                }
                this.getResults();
            }
        },
        // Delete only one record
        deleteRecord(pageId) {
            axios.delete(`/admin/pages/delete-record/${pageId}`)
            .then((response) => {
                if(response.status) {
                    this.getResults();
                    this.$toast.success('Seite erfolgreich gelöscht');
                } else {
                    this.$toast.error('Fehler beim Löschen!');
                }
            });
        },
        // Delete multiple pages in one request
        deleteRecords() {
            axios.delete(`/admin/pages/mass-delete/${this.checkedPages}`)
                .then((response) => {
                    // we are catching the status code 204 which means no content without error
                    if(response.status == 204) {
                        this.getResults();
                        this.$toast.success('Ausgewählte Seiten erfolgreich gelöscht!');
                        this.checkedPages = [];
                    } else {
                        this.$toast.error('Fehler! Seiten konnten nicht gelöscht werden');
                    }
                });
        },
    },
    created() {
        // get all tests when the component first loaded
        if(this.currentPageId) {
            this.checkedPages.push(this.currentPageId);
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            if(params.get('current_page')) {
                this.getResults(params.get('current_page'));
            } else if(params.get('q')) {
                this.search = params.get('q');
            } else {
                this.getResults();
            }
        } else 
        {
            this.getResults();
        }
    }
}
</script>